//
function input_radio_structured_list_click(imput_radio) {

    if (document.getElementById('form_unique')) {
        formx = document.getElementById('form_unique');

        let guid_finding = imput_radio.value;
        if (guid_finding =='') {
            console.log('!important not value guid select');
        } else {
            formx.guid_track.value = guid_finding;
        }

        if (imput_radio.dataset.toolbar) {
            // console.log('toolbar: '+imput_radio.dataset.toolbar);
        }
        
        if (imput_radio.dataset.preview) {
            // console.log('toolbar: '+imput_radio.dataset.preview);
            formx.guid_preview.value = imput_radio.dataset.preview;
        }

        if (imput_radio.dataset.requirement) {
            // console.log('requirement: '+imput_radio.dataset.requirement);
        }

        if (imput_radio.dataset.analytics) {
            // console.log('analytics: '+imput_radio.dataset.analytics);
            formx.guid_analytics.value = imput_radio.dataset.analytics;
        }

        let DataGroup = imput_radio.dataset.group;

        let tbp_control_buttons = document.getElementById('tbp_control_buttons');
        let btns_TBP = tbp_control_buttons.querySelectorAll('.function');
        // console.log('btns_TBP: ' + btns_TBP.length);

        let classAll;
        let classGroup;

        for (const iterator of btns_TBP) {

            if (iterator.classList.contains('all')) {
                // console.log('un btn .all');
            } else {

                if (iterator.classList.contains(DataGroup)) {
                    iterator.style.display = 'block';
                    // console.log('un btn '+DataGroup);
                } else {
                    iterator.style.display = 'none';
                    // console.log('otro btn '+DataGroup);                
                }
            }
        }
        
    }

}

// group A .assigner
// group V .validator
// group E .edition
// group R .findings
// group P .actionplan
// group G
