// Fetch Option List
async function Fetch_OptionList(
    xpath :string,
    SelectOrgn,
    SelectDest
) {
    let codeHttp;
    let timer:number = -(new Date().getTime());
    let Qty:number =0;
    let NamePost = SelectOrgn.name.toString();
    let ValuePost = SelectOrgn.options[SelectOrgn.selectedIndex].value.toString();
    
    let frmData = new FormData;
    frmData.append(NamePost, ValuePost);

    SelectDest.innerHTML = '<option class="select_stand_by" disabled selected>Actualizando .....</option>';

    if (ValuePost.trim() == '') {
        SelectDest.innerHTML = '<option class="select_stand_by" disabled selected>Favor Seleccionar ...</option>';
        return false;
    }
    
    const request = new Request(xpath, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'same-origin', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'same-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: frmData,
        // headers: {
        // 'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
        // }
    });

    const fres = await fetch(request)
        .then(res => {
            codeHttp = res.status;

            switch (res.status) {
                case 200:
                    return res.text();
                    // return res.json();
                    break;

                case 201:
                    break;

                case 400:
                    // if (res.code === 'ValidationFailed') {
                    //     // My custom error messages from the API.
                    //     console.log(res.fieldMessages)
                    // } else {
                    // }
                    break

                case 500:
                    break

                default:
                    throw res.status.toString() + 'Unhandled'
                    break
            }
        })
        .then(jsonData => {
            let arrayResponse = JSON.parse(jsonData);
            Qty = arrayResponse['OPTION'].length;

            if (arrayResponse['OPTION'].length !== 0) {

                SelectDest.innerHTML = arrayResponse['OPTION'];
                // arrayResponse['OPTION'].forEach(SelectDest => {
                //     // console.log(SelectDest.GUID_COLLABORATOR);
                //     formx.areaprocess.innerHTML = SelectDest.NAME_FIRST;

                // });

            } else {
                SelectDest.innerHTML = '';
            }

            // if (Array.isArray(arrayResponse)) {
            //     console.log('arrayResponse isArray');

            //     if (arrayResponse.includes('MSG_00')) {
            //         console.log('mensaje oculto');
                    
            //     }
                
            // }

            timer += new Date().getTime();
            console.info(codeHttp, 'Fetch', xpath, (timer /1000).toFixed(3), 's');
        })
        .catch((err) => {
            FetchController(err);
        });
}
