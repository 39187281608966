//
function check_input_email(
    element: object,
    message:string ='',
    title:string=null,
    option=null,
    context:any=null
) {
    let detect:boolean =false;
    let flagtype:string ='I';
    let dataValue = element.value;
    let filter04 = /\S+@\S+\.\S+/;
    
    // console.log('dataValue: '+dataValue.trim());
    // console.log(filter04.test(dataValue.trim()));
    
    if (message =='') {
        message = 'El campo Email se encuentra en blanco';
    }

    switch (true) {
        // case (!element.validity.valid):
        //     // for clean error message  
        //     detect = true;          
        //     break;

        case (dataValue == ''):
            detect = true;
        break

        case (filter04.test(dataValue.trim())):
            message = 'El email ingresado no es válido';
            detect = true;
        break

        case (element.validity.valueMissing):
            message = 'Debe introducir una dirección de correo electrónico';
            detect = true;
        break

        case (element.validity.typeMismatch):
            message = 'El valor introducido debe ser una dirección de correo electrónico';
            detect = true;
        break

        // case (element.validity.tooShort):
        //     message = 'El correo electrónico debe tener al menos '+ element.minLength +' caracteres; ha introducido '+ dataValue.length;
        //     detect = true;
        // break
    }

    if (Array.isArray(option)) {

        if (option.includes('perro')) {

        }
        
    }   

    if (detect) {
        NotifyerPopUp.create(message + Prefix, title, flagtype);
        focus_scroll(element);
        throw new ExceptionInput('Check Email', element, message);
    }

    return detect;
}
