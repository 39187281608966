//
function check_input_file(
    element: object,
    message:string ='',
    title:string=null,
    option=null,
    context:any=null
) {
    let detect:boolean =false;
    let flagtype:string ='I';
    let filelenght = element.files.length;

    switch (true) {
        // case (!element.validity.valid):
        //     // for clean error message  
        //     detect = true;          
        //     break;

        case (filelenght == 0):
            detect = true;
        break
    }

    if (detect) {
        NotifyerPopUp.create(message + Prefix, title, flagtype);
        focus_scroll(element);
        throw new ExceptionInput('Check File', element, message);
    }

    return detect;
}
