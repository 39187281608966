// Copyright Platform
function CopyrightConsole() {

    console.groupCollapsed('%c  INNOMOTICS a Siemens Business               ', 'background-color:#099;color:#fff;', '[LDA AM CL IPM], Developer Team [DEV SW SICL]');
        console.groupCollapsed('%c Policy', 'color:#099');
            console.info('%c Copyright 2021 INNOMOTICS a Siemens Business', 'color:#555');
        console.groupEnd();
        console.groupCollapsed('%c Digital Implementations', 'color:#099');
            console.info('%c LDA CS S SO, Francisco Barrientos Weston', 'color:#555');
            console.info('%c LDA AM RC-CL CS, Business Development Manager, Jorge Rico Rojas', 'color:#555');
            console.info('%c IN AM RC-CL CS IPM, Project Management Manager, ✞ Juan Mella Vargas', 'color:#555');
            console.info('%c IN AM RC-CL CS IPM, Customer Services Professional, Edgard Iturrieta', 'color:#555');
            console.info('%c IN AM RC-CL CS IPM, Quality Professional, Mauricio Reyes Diaz', 'color:#555');
            console.info('%c IN AM RC-CL CS IPM, Service Operations Manager, Dagoberto Astudillo Parra', 'color:#555');
        console.groupEnd();
        console.groupCollapsed('%c Developer Team', 'color:#099');
            console.info('%c Cloud Manager, Edwin Pizarro Araya', 'color:#555');
            console.groupCollapsed('%c Contributors', 'color:#099');
                console.info('%c Service Operations Team Leader, Patricio Bascunan', 'color:#555');
                console.info('%c IT Software Developer, Diego Molina Saez', 'color:#555');
                console.info('%c Service Operations Team Leader, Hugo Alvear', 'color:#555');
                console.info('%c Service Operations Team Leader, Cesar Roa', 'color:#555');
            console.groupEnd();
        console.groupEnd();
    console.groupEnd();
}

CopyrightConsole();
