//
function rview_doc_custom(idx, sect) {

    if (sect == 'MPRO') {
        path = '?idx=' + idx + '&accn=core/pdf/setprnt/doc_mproc';
        window.open(path);
    }

    if (sect == 'MRSK') {
        path = '?idx=' + idx + '&accn=core/pdf/setprnt/doc_mrisk';
        window.open(path);
    }

    if (sect == 'STDJ') {
        window.open(path);
    }

    if (sect == 'DHDS') {
        path = 'bank.file/fhds/' + idx + '.pdf';
        window.open(path);
    }

    if (sect == 'DIEN') {
        path = 'bank.file/dien/' + idx + '.pdf';
        window.open(path);
    }
}

function sign_doc_custom(form) {
    AnimationWallProgress(true);

    path = '?idx=' + form.idx.value + '&accn=core/sign/setsign/capturesign';
    form.target = '';
    form.action = path;
    form.submit();
}

function doc_reject(idx, sect) {
    let form = document.forms['form_workpack'];

    document.getElementById('reg_commts').style.display = 'block';

    if (form.incoments.value == '') {
        NotifyMssg = 'Favor ingrese un comentario con las indicaciones sobre los detalles encontrados que no cumplan con la revisión';
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'I');
        form.incoments.focus();
        return false;
    }

    AnimationWallProgress(true);
    form.xtas.value = 'RJCT';

    path = '?accn=core/sign/accsign/doc_reject';

    form.action = path;
    form.submit();
}
