/*
https://www.tremplin-numerique.org/en/comment-gerer-les-notifications-web-push-dans-les-sites-web-et-les-pwa
https://diarioinforme.com/como-administrar-notificaciones-push-web-en-sitios-web-y-pwa/

https://developer.mozilla.org/es/docs/Web/API/Notifications_API/Using_the_Notifications_API

*/

function send_NotificationToast(
    Title:string='',
    Body:string='',
    Type:string='info'
) {
    console.info('send_NotificationToast');

    if ('Notification' in window) {
        if (Notification.permission === 'granted') {
            deploy_NotificationToast(Title, Body, Type);
        } else {

            Notification.requestPermission().then((status)=>{
                if (status === "granted") {
                    deploy_NotificationToast(Title, Body, Type);
                }
            });
        }

    } else {
        console.info('Notifications not support')
    }
}


function deploy_NotificationToast(
    Title:string='',
    Body:string='',
    Type:string='info'
) {
    let icon:string ='';
    let i = 0;
    
    switch (Type) {
        case 'alert':
            icon ='/Workers/media/icon-32.png';
            break;

        case 'info':
            icon ='/Workers/media/icon-32.png';
            break;

        case 'danger':
            icon ='/Workers/media/icon-32.png';
            break;

        case 'success':
            icon ='/Workers/media/icon-32.png';
            break;

        case 'warning':
            icon ='/Workers/media/icon-32.png';
            break;
    }

    let noti = new Notification(Title, {
        icon: icon,
        body:Body,
        data:{
            url:'https://sipmcore.com'
        }
        // actions:[
        //     {
        //         action:'/ Home',
        //         title: 'Homescreen',
        //         icon: '/icon-32.png',
        //     }
        // ]
    })

    noti.onclick = (event)=>{
        // console.info('click', event);
    };

    noti.onclose = (event)=>{
        // console.info('close', event);
    };

    noti.onerror = (event)=>{
        // console.info('error', event);
    };

    setTimeout(() => {noti.close()}, 7000);

    let interval = window.setInterval(function () {

        if (i++ == 9) {window.clearInterval(interval);}
    }, 200);
}
