// 
function loading_inner_html(id) {
    let element = document.getElementById(id);
    element?.innerHTML = '';

    let div = document.createElement('div');
    div.setAttribute('data-loading', '');
    div.classList.add('bx--loading');
    div.innerHTML = '<svg class="bx--loading__svg" viewBox="-75 -75 150 150"><title>Loading</title><circle class="bx--loading__stroke" cx="0" cy="0" r="37.5" /></svg>';
    
    // let svg = document.createElement('svg');
    // svg.classList.add('bx--loading__svg');
    // svg.setAttribute('viewBox', '-75 -75 150 150');

    // let title = document.createElement('title');
    // title.innerHTML = 'Loading';
    // svg?.appendChild(title)

    // let circle = document.createElement('circle');
    // circle.classList.add('bx--loading__stroke');
    // circle.setAttribute('cx', '0');
    // circle.setAttribute('cy', '0');
    // circle.setAttribute('r', '37.5');
    // svg?.appendChild(circle);

    // div?.appendChild(svg)
    element?.appendChild(div)
}
