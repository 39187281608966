//
function check_input_password(
    element:object,
    message:string ='',
    title:string=null,
    option=null,
    context:any=null
) {
    let detect:boolean =false;
    let flagtype:string ='I';
    let dataValue = element.value;
    let re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;

    if (message =='') {
        message ='La contraseña se encuentra en blanco';
    }
    
    if (element.validity.valid) {
        // for clean error message
    }

    if (!element.validity.valid) {

        if(element.validity.valueMissing) {
            message = 'El Password se encuentra en blanco';
            detect = true;
        }

    // } else if (!re.test(dataValue.trim())) {
    //     message ='El Password al menos debe contener un numero, letras minúsculas y letras mayúsculas';
    //     detect = true;

    } else {
        let HardCheck = true;

        if (Array.isArray(option)) {

            if (option.includes('confirm')) {

                if (dataValue.trim() != context.value.trim()) {
                    message = 'La Contraseña y confirmación de contraseña no coinciden';
                    detect = true;
                }
            }

            if (option.includes('light')) {
                HardCheck = false;
            }
                
        } else if (dataValue.length < 12) {
            message ='La contraseña debe ser de al menos 12 digitos';
            detect = true;
    
        }
        
    }

    //Regular Expressions.
    // let passed = 0;
    // let regex = new Array();
    // regex.push('[A-Z]'); 
    // regex.push('[a-z]'); 
    // regex.push('[0-9]'); 
    // regex.push('[$@$!%*#?&]'); 

    // if (new RegExp('[A-Z]').test(dataValue)) {
        // Uppercase Alphabet.
    // }

    // if (new RegExp('[a-z]').test(dataValue)) {
        // Lowercase Alphabet.
    // }

    // if (new RegExp('[0-9]').test(dataValue)) {
        // Digit.
    // }

    // if (new RegExp('[$@$!%*#?&]').test(dataValue)) {
        // Special Character.
    // }

    if (detect) {
        NotifyerPopUp.create(message + Prefix, title, flagtype);
        focus_scroll(element);
        throw new ExceptionInput('Check Password', element, message);
    }

    return detect;
}
