//
if (document.querySelectorAll('.section_download_file')) {

    let btnsDownload = document.querySelectorAll('[data-download]');

    if (btnsDownload) {
        let xpath = '';

        btnsDownload.forEach(btn => {
            btn.addEventListener('click', event => {

                let btn_dataset = event.target.dataset;
                
                let guiddoc = btn_dataset.download;
                let manifest = btn_dataset.manifest;

                xpath = '/DMS/Query/Findings/DownloadEvidence?guiddoc='+guiddoc+'&manifest='+manifest;
                
                if (manifest) {                    
                    window.open(xpath, '_blank');
                }
            })
        });
                
    }
}
