
// Worker Reload Environment Developed
function active_ReloadService() {

    if (typeof (Worker) !== 'undefined') {
        // console.log('1 Reload Service On');

        let worker00 = new Worker('./wkr/reload.js');
        worker00.onerror = errorReceiver;

        worker00.addEventListener('message', function (oEvent) {
            console.log('Response: ' + oEvent.data);

            switch (oEvent.data) {
                case 'RELOAD':
                    console.log('switch > ' + oEvent.data);
                    window.location.reload();
                    break;

                default:
                    break;
            }

        }, false);

        // start the worker
        // worker00.postMessage('RELOAD');

    } else {
        // console.log('Service Worker Not Support!');
    }
}

function errorReceiver(event) {
    throw event.data;
}

function action_reload() {
}

// Active_ReloadService();
