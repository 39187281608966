//** Toolbar Multiple Action X */

if (document.getElementById('maxtoolbar')) {

    let xtoolbar = document.getElementById('maxtoolbar');
    let maxtb_closer = document.getElementById('maxtb_closer');

    maxtb_closer.addEventListener('click', function (e) {
        xtoolbar.classList.remove('show_toolbar');
        xtoolbar.classList.add('hidden_toolbar');
    })

}
