//
function AnimationWallProgress(stats:boolean=true) {
    let wait_point = document.getElementById('wait_point');

    if (stats) {
        wait_point.style.display = 'block';
    } else {
        wait_point.style.display = 'none';
    }
}
