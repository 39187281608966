//
function Validation_Input(
    element:object,
    message:string,
    title:string=null,
    option=null,
    context:any=null
) {
    let check:boolean = false;
    let res:boolean = false;

    if (title ==null) {
        title = NotifyTitle;
    }
    
    switch (element.type) {
        case 'button':
            check = true;
            break;

        case 'checkbox':
            res = check_input_checkbox(element, message, title);
            break;

        case 'color':
            check = true;
            break;

        case 'date':
            res = check_input_date(element, message, title);
            break;

        case 'datetime-local':
            check = true;
            break;

        case 'email':
            // seudoclass > :valid - :invalid
            res = check_input_email(element, message, title, option);
            break;

        case 'file':
            // attrb > accept MIME audio/* video/* image/*
            res = check_input_file(element, message, title);
            break;

        case 'hidden':
            res = check_input_hidden(element, message, title, option);
            break;

        case 'image':
            // attrb > alt height width
            check = true;
            break;

        case 'month':
            check = true;
            break;
            
        case 'number':
            res = check_input_number(element, message, title, option);
            break;

        case 'password':
            res = check_input_password(element, message, title, option, context);
            // attrb > maxlength
            break;

        case 'radio':
            // attrb > value checked name
            check = true;
            break;

        case 'range':
            // attrb > min max value step
            check = true;
            break;

        case 'reset':
            check = true;
            break;

        case 'search':
            check = true;
            break;

        case 'select-one':
            res = check_input_select_one(element, message, title);
            break;

        case 'submit':
            check = true;
            break;

        case 'tel':
            // attrb > pattern maxlength / :valid / :invalid
            check = true;
            break;

        case 'text':
            res = check_input_text(element, message, title, option, context);
            break;

        case 'textarea':
            res = check_input_textarea(element, message, title);
            break;

        case 'time':
            check = true;
            break;

        case 'url':
            res = check_input_url(element, message, title);
            break;

        case 'week':
            check = true;
            break;
    }

    // Optional config
    if (check) {
        throw new ExceptionInput('Input need Configurate : ', element, 'Validation Input');       
    }

    return res;
}
