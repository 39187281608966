//** LINK AUDITOR CLIPBOARD */
if (document.getElementById('auditor_clipboard')) {
    let auditor_clipboard = document.getElementById('auditor_clipboard');

    auditor_clipboard.addEventListener('click', function (e) {
        const cb = navigator.clipboard;
        let notify_clip = 'Información copiada al portapapeles de Windows....';

        cb.writeText(auditor_clipboard.value).then(() => NotifyerPopUp.create(notify_clip, 'Clipboard', 'I'));
    })

}
