//
function check_input_url(
    element:object,
    message:string ='',
    title:string=null,
    option=null,
    context:any=null
) {
    let detect:boolean =false;
    let flagtype:string ='I';
    let dataValue = element.value;

    if (message =='') {
        message = 'La URL se encuentra en blanco';
    }

    if (dataValue == '') {
        detect = true;

    } else if (Array.isArray(option)) {

    }

    if (detect) {
        NotifyerPopUp.create(message + Prefix, title, flagtype);
        focus_scroll(element);
        throw new ExceptionInput('Check Text', element, message);
    }

    return detect;
}
