// Fetch Data Json
async function Fecth_DataPost(
    xpath :string,
    DataPost={},
    Callback
) {
    let codeHttp;
    let timer:number = -(new Date().getTime());
    let Qty:number =0;

    if (!Object.keys(DataPost).length) {
        throw 'Data Post Empty!'
    }

    let frmData = new FormData;
    for(let item in DataPost) {
        // console.log(item, DataPost[item]);
        frmData.append(item, DataPost[item]);
    }
    
    const request = new Request(xpath, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'same-origin', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'same-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: frmData,
        headers: {
            'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
        }
    });

    const fres = await fetch(request)
        .then(res => {
            codeHttp = res.status;

            switch (res.status) {
                case 200:
                    // return res.text();
                    return res.json();
                    break;

                case 201:
                    break;

                case 400:
                    // if (res.code === 'ValidationFailed') {
                    //     // My custom error messages from the API.
                    //     console.log(res.fieldMessages)
                    // } else {
                        // console.info('400 this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)')
                    // }
                    break

                case 500:
                    break

                default:
                    throw 'Unhandled'
                    break
            }
        })
        .then(data => {

            // let jsonResponse = JSON.parse(data);
            // Qty = jsonResponse['OPTION'].length;

            // Callback(jsonResponse);

            // if (jsonResponse['OPTION'].length !== 0) {
            

                // SelectDest.innerHTML = jsonResponse['OPTION'];

                // jsonResponse['OPTION'].forEach(SelectDest => {
                //     // console.log(SelectDest.GUID_COLLABORATOR);
                //     formx.areaprocess.innerHTML = SelectDest.NAME_FIRST;

                // });

            // } else {
            // }

            // if (Array.isArray(jsonResponse)) {
            //     console.log('jsonResponse isArray');

            //     if (jsonResponse.includes('MSG_00')) {
            //         console.log('mensaje oculto');
                    
            //     }
                
            // }

            timer += new Date().getTime();
            console.info(codeHttp, 'Fetch', xpath, (timer /1000).toFixed(3), 's');
        })
        .catch((err) => {
            FetchController(err);
        });
}
