//
function check_input_hidden(
    element: object,
    message:string ='',
    title:string=null,
    option=null,
    context:any=null
) {
    let detect:boolean =false;
    let flagtype:string ='I';
    let elementFocus:object;

    if (element.value == '') {

        if (Array.isArray(option)) {

            if (option.includes('SignaturePad')) {
                elementFocus = document.getElementById('sign_imageunique');
                focus_scroll(elementFocus);
            }
        }

        detect = true;
    }

    if (detect) {
        NotifyerPopUp.create(message + Prefix, title, flagtype);
        // focus_scroll(element);
        throw new ExceptionInput('Check Hidden', element, message);
    }

    return detect;
}
