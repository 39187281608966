// Define Wide Banner

if (document.getElementById('wide_banner')) {

    let wide_banner = document.getElementById('wide_banner');

    setTimeout(function () {
        hidden_wide_banner();
    }, 8000);

    let WideBannerDescription = wide_banner.querySelectorAll(".WideBannerDescription");
    
    WideBannerDescription[0].addEventListener('click', function (e) {
        wide_banner.style.opacity = '1';
        wide_banner.style.display = 'none';
    });
}
