// Toolbar Pro
if (document.getElementById('toolbar_pro')) {

    let panel_tbp = document.getElementById('panel_tbp');
    let closer_tbp = document.getElementById('closer_tbp');

    closer_tbp.addEventListener('click', function (e) {
        panel_tbp.classList.remove('show_toolbar');
        panel_tbp.classList.add('hidden_toolbar');
    })

    mapping_buttons_toolbar_pro();
}
