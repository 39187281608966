//
function check_validate_rundv(
    element_run:object,
    element_dv:object,
    message:string ='',
    title:string='',
    option=null,
    context:any=null
) {
    let detect:boolean =false;
    let flagtype:string ='I';
    let data_run = element_run.value;
    let data_dv = element_dv.value;

    let long_run = data_run.toString() + '-' + data_dv.toString();
    
    if (data_run.length > 8) {
        message = 'El el bloque RUN ingresado es mayor a 8 Digitos';
        detect = true;

    } else if (!Fn.validaRut(long_run)) {
        message = 'RUN erroneo, No es congruente con el Digito Verificador';
        detect = true;
    }
    

    if (detect) {
        NotifyerPopUp.create(message + Prefix, title, flagtype);
        focus_scroll(element_run);

        throw new ExceptionInput('Check Text', element_run, message);
    }

    return detect;
}
