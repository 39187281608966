//
function map_ButtonsMenu(xmenu) {

    xmenu.addEventListener('click', function (e) {
        let action = e.target.dataset.menu;
        let new_target:boolean = false;
        let fmenu = document.forms['form_menu_drawer'];

        switch (action) {
            // SHARED
            case 'MENU': open_menu(xmenu); break;
            case 'OpenHomeFeature':
                fmenu.target = '';
                let xpath = fmenu.button_home.value;
                goLink(xpath);
                break;
            case 'CheckApprovedDocuments': goLink('/DMS/Command/Approvals/SignatureWaitDocument'); break;
            case 'NewRecordFinding': goLink('/ProcessAssurance/Command/Findings/FormProtocolRegister'); break;

            // COMERCIAL PROJECT
            //

            // CONTRACT MANAGEMENT - ADMINISTRACIÓN DEL CONTRATO
            case 'HomeContractManagement': goLink('/ContractManagement/Command/Manager/Home'); break;
            //

            // CONTRACT PERFONMANCE - GESTION DESEMPEÑO DEL CONTRATO
            case 'HomeContractPerformance': goLink('/ContractPerformance/Command/Manager/Home'); break;
            //

            // EVELOPMENT
            //

            // DOCUMENT MANAGEMENT SYSTEM - SISTEMA GESTION DOCUMENTAL
            case 'HomeDMS':                goLink('/DMS/Command/Manager/Home'); break;
            //

            // EHSQ - GESTION DE EHSQ
            case 'HomeEHSQ': goLink('/EHSQ/Command/Manager/Home'); break;
            //

            // PMPI
            //

            // EQINSPECTOR
            case 'EQINS_IDLERS':        goLink('/EQInspector/Command/Manager/Belts'); break;
            case 'EQINS_PLATES':        goLink('/EQInspector/Command/Manager/DeskPlates'); break;
            case 'EQINS_LUBRICATION':   goLink('/EQInspector/Command/Manager/DeskLubrication'); break;
            case 'EQINS_VENTILATION':   goLink('/EQInspector/Command/Manager/DeskVentilation'); break;
            //

            // EXECUTION SERVICE - EJECUCIÓN DEL SERVICIO
            case 'HomeExecutionService': goLink('/ExecutionService/Command/Manager/Home'); break;
            //

            // MANAGEMENT HUMAN RESOURCES - GESTION DE RRHH
            case 'HomeHumanResources':          goLink('/HumanResources/Command/Manager/Home'); break;
            case 'ControlPersonalRequeriment':  goLink('/HumanResources/Command/StaffRequirement/ContractControl'); break;
            case 'ControlMedicalLicense':       goLink('/HumanResources/Command/MedicalLicenses/DeskMedicalLicenses'); break;
            case 'ControlPermissWork':          goLink('/HumanResources/Command/PermissWork/DeskPermissWork'); break;
            //

            // LOGIGISTIC - GESTION LOGISTICA
            case 'HomeLogistics': goLink('/Logistics/Command/Manager/Home'); break;
            //

            // PLANNING - GESTIÓN PROGRAMACIÓN
            case 'HomePlanning': goLink('/Planning/Command/Manager/Home'); break;
            //

            // PROCESS ASSURANCE - ASEGURAMIENTO DE GESTION POR PROCESOS
            case 'HomeProcessAssurance': goLink('/ProcessAssurance/Command/Manager/Home'); break;
            case 'MYHLLZG':         goLink('/PMPI/Command/Findings/DeskMyEvents'); break;
            case 'CONTROLHLLZG':    goLink('/ProcessAssurance/Command/Findings/DeskControlContract'); break;
            case 'PROCESSHLLZG':    goLink('/ProcessAssurance/Command/Findings/DeskControlProcess'); break;
            case 'ACTIONPLANHLLZG': goLink('/ProcessAssurance/Command/Findings/DeskActionPlans'); break;
            //

            // QRTRACKING
            //

            // QUALITY MANAGEMENT - ASEGURAMIENTO DE QA/QC
            case 'QLTYMG': goLink('/QualityManagement/Command/Manager/Home'); break;
            //

            // REGISTER AND EVIDENCE
            //

            // RELIABILITY - CONFIABILIDAD
            case 'HomeReliability': goLink('/Reliability/Command/Manager/Home'); break;
            //

            // RISK MANAGEMENT - GESTION DEL RIESGO
            //

            // STAFF RECRUITMENT - RECLUTAMIENTO DE PERSONAL
            case 'ApplicantPersonalData':   goLink('/StaffRecruitment/Command/Applicants/DataPersonal'); break;
            case 'ApplicantRolePreferences': goLink('/StaffRecruitment/Command/Applicants/RolPreference'); break;
            case 'ApplicantDocumentsDegree': goLink('/StaffRecruitment/Command/Applicants/Documents'); break;
            //

            // MAIN SYSTEM
            case 'ProfileManagement':       goLink('/System/Command/Accounts/ManagementProfiles'); break;
            case 'DigitalImplementations':  goLink('/Development/Command/Manager/Home'); break;
            case 'AccessLocalConfig':       goLink('/System/Command/Settings/PreferencesEnvironment'); break;
            case 'LogOut':                  goLink('/System/Service/Access/LogOut'); break;
            //

            // TICA
            //

            // TOOLTIME
            //

            // WORKPACKS
            case 'HomeWorkPacks': goLink('/WorkPacks/Command/Manager/Home'); break;
            // DEPRECATED
            case 'EXPLR':   goLink('?accn=wrkpcks/dshboard/docspacks/Home'); break;
            case 'PGRM':    goLink('?accn=planner/actions/prgm/pmain'); break;
            case 'DCEMP':   goLink('?accn=partner/dshboard/company/biglist'); break;
            case 'DCCLI':   goLink('?accn=corp/dshboard/cmdic/base'); break;
            case 'HELP':    goLink('?accn=core/help/desk/base'); break;
            case 'NWPCK':   
                new_target=true;
                pathx = '/wrkpcks/dshboard/docspacks/newwpack';
                break;
        }

        if (new_target) {
            fmenu.target = '_blank';
            fmenu.action = pathx;
            fmenu.submit();
            return true;
        }
    })
}
