//

function check_TimeClient() {

    // let zoneIANA = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let date = new Date();
    let aaaa = date.getFullYear();
    let mm = (date.getMonth() + 1);
    let gg = date.getDate();

    let cur_day = aaaa + "-";

    if (mm < 10) {
        cur_day += "0";
    }
    cur_day += mm.toString() + '-';

    if (gg < 10) {
        cur_day += "0";
    }
    cur_day += gg.toString() + ' ';

    let hh = date.getHours()
    let mi = date.getMinutes()
    let se = date.getSeconds();

    if (hh < 10) {
        cur_day += "0";
    }
    cur_day += hh.toString() + ':';

    if (mi < 10) {
        cur_day += "0";
    }
    cur_day += mi.toString() + ':';

    if (se < 10) {
        cur_day += "0";
    }
    cur_day += se.toString();

    return cur_day;
}

function format_DateServer(fecha, formato) {
    const map = {
        dd: fecha.getDate(),
        mm: fecha.getMonth() + 1,
        yy: fecha.getFullYear().toString().slice(-2),
        yyyy: fecha.getFullYear()
    }

    return formato.replace(/dd|mm|yy|yyy/gi, matched => map[matched])
}
