//
function open_menu(xmenu) {
    //console.log('menu click');

    if (!xmenu.classList.contains('sidenav-counter-expand-sm')) {
        xmenu.classList.add('sidenav-counter-expand-sm');
        //setTimeout(function(){close_menu()}, 10000);
    }
}

function close_menu(xmenu) {
    xmenu.classList.remove('sidenav-counter-expand-sm');
}

if (document.getElementById('xmenu')) {
    let xmenu = document.getElementById('xmenu');
    let SiteContent = document.getElementById('SiteContent');

    close_menu(xmenu);

    xmenu.addEventListener('keypress', function (e) {
        if (e.code === 'Escape') {
            close_menu(xmenu);
        }
    })

    xmenu.addEventListener('mouseover', function (e) {
        if (!xmenu.classList.contains('sidenav-counter-expand-sm')) {
            SiteContent.classList.add('opacityHid');

            if (document.getElementById('foot_endline')) {
                document.getElementById('foot_endline').classList.add('opacityHid');
                
            }
            
            xmenu.classList.add('sidenav-counter-expand-sm');
        }
    })

    xmenu.addEventListener('mouseleave', function (e) {
        SiteContent.classList.remove('opacityHid');

        if (document.getElementById('foot_endline')) {
            document.getElementById('foot_endline').classList.remove('opacityHid');
            
        }

        xmenu.classList.remove('sidenav-counter-expand-sm');
    })

    map_ButtonsMenu(xmenu);
}
