// Fetch Data Json
async function Fetch_DataJson(
    xpath :string,
    DataPost={},
    Callback
) {
    let codeHttp;
    let timer:number = -(new Date().getTime());
    // let Qty:number =0;

    if (!Object.keys(DataPost).length) {
        throw 'Data Post Empty!'
    }

    const request = new Request(xpath, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'same-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(DataPost),
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        }
    });

    const fres = await fetch(request)
        .then(res => {
            codeHttp = res.status;

            switch (codeHttp) {
                case 200:
                    // return res.text();
                    break;
                default:
                    console.log('status:',codeHttp);
                    break
            }
            return res.json();
        })
        .then(data => {
            let jsonResponse = JSON.stringify(data);

            Callback(jsonResponse);

            timer += new Date().getTime();
            const action = xpath.split('/').pop();
            console.info(codeHttp, (timer /1000).toFixed(3).toString() + 's', action?.toString());
        })
        .catch((err) => {
            FetchController(err);
        });
}
