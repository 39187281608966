//
if (document.querySelectorAll('.core_file_input')) {
    let CoreFileInput = document.querySelectorAll('.core_file_input');

    Array.prototype.forEach.call(CoreFileInput, function (fileInput) {
        let label = fileInput.nextElementSibling,
            labelVal = label.innerHTML;
        console.log('labelVal:' + labelVal);

        fileInput.addEventListener('change', function (e) {
            let fileName = this.files.value;
            // fileName = fileName.replace(/\\/g, '/').replace(/.*\//, '');

            console.log('fileName:' + fileName);


            if (fileName) {
                label.querySelector('label').innerHTML = fileName;
            } else {
                label.innerHTML = labelVal;
            }

        });
    });

}
