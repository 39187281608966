//
function check_input_text(
    element:object,
    message:string ='',
    title:string='',
    option=null,
    context:any=null
) {
    let detect:boolean =false;
    let flagtype:string ='I';
    let dataValue = element.value;
    
    switch (true) {
        // case (!element.validity.valid):
        //     // for clean error message  
        //     detect = true;          
        //     break;

        case (element.value ==''):
            message = 'El registro se encuentra en blanco';
            detect = true;
        break

        case (element.validity.valueMissing):
            message = 'El registro se encuentra en blanco';
            detect = true;
        break
    }

    if (Array.isArray(option)) {

        switch (true) {
            case option.includes('names'):
                // let filter01 = /\d/; // Coincide con cualquier carácter no numérico. Equivalente a [^0-9].
                // let filter02 = /\s/; // Coincide con un carácter de espacio, entre ellos incluidos espacio, tab, salto de página, salto de linea y retorno de carro.
                let filter03 = /\d+\s/;
    
                if (filter03.test(dataValue.trim()) ) {
                    message = 'El campo ingresado solo debe contener letras';
                    detect = true;
                }
                break;

            case option.includes('minlength'):    
                if (dataValue.length < parseInt(context)) {
                    message = 'El dato ingresado debe ser menor a ' + context.toString() + ' Digitos';
                    detect = true;
                }
                break;

            case option.includes('maxlength'):
                if (dataValue.length >= parseInt(context)) {
                    message = 'El dato ingresado debe es mayor a ' + context.toString() + ' Digitos';
                    detect = true;
                }
                break;

            case option.includes('run'):
                if (dataValue.length <= 8) {
                    message = 'El RUN ingresado debe es mayor a 8 Digitos';
                    detect = true;
    
                } else if (!Fn.validaRut(element.value)) {
                    message = 'RUN erroneo, No es congruente con el Digito Verificador';
                    detect = true;
                }
                break;
        
            case option.includes('passport'):
                message = 'passport';
                break;
        
            case option.includes('dni'):
                message = 'dni';
                break;
        }        
    }

    if (detect) {
        NotifyerPopUp.create(message + Prefix, title, flagtype);
        focus_scroll(element);

        throw new ExceptionInput('Check Text', element, message);
    }

    return detect;
}
