//

function update_FileImageToCharge() {

    file_uploader.style.opacity = "0";
    const preview = document.querySelector('.preview');

    while (preview.firstChild) {
        preview.removeChild(preview.firstChild);
    }

    const curFiles = file_uploader.files;
    if (curFiles.length === 0) {
        const _p = document.createElement('p');
        _p.textContent = 'Actualmente no hay archivos seleccionados para cargar.';
        preview.appendChild(_p);

    } else {
        const _ol = document.createElement('ol');
        preview.appendChild(_ol);

        for (const file of curFiles) {
            const _li = document.createElement('li');
            const _p = document.createElement('p');

            if (valid_FileTypeToCharge(file)) {
                // _p.textContent = `Archivo ${file.name}, tamaño ${get_SizeFileToCharge(file.size)}.`;
                _p.textContent = `${get_SizeFileToCharge(file.size)}.`;
                // const _img = document.createElement('img');
                // _img.src = URL.createObjectURL(file);

                // _li.appendChild(_img);
                _li.appendChild(_p);

            } else {
                _p.textContent = `Archivo ${file.name}: Tipo de Archivo no valido. Actualice su selección.`;
                _li.appendChild(_p);
            }

            _ol.appendChild(_li);
        }
    }
}
