// Fetch Data Json
async function FetchSimplePostJson(
    xpath :string,
    DataPost={}
) {
    if (!Object.keys(DataPost).length) {
        throw 'Data Post Empty!'
    }

    DataPost['timer'] = new Date().getTime();
    
    const response = await fetch(xpath, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'same-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(DataPost),
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        }
    }).catch(err=>{
        ErrorController(err);
    });

	if (!response.ok) {
		throw new Error(`HTTP Err response: ${response.status}`);
	}
    
    return await response.json();
}
