//

function get_InfoFileToCharge(
    InputBtnFile,
    TitleBtn,
    LabelMessage
) {
    let description:string ='';
    let meta:string ='';
    let nameFile:string ='';

    const curFiles = InputBtnFile.files;

    if (curFiles.length === 0) {
        description = 'No ha seleccionado un archivo para cargar';

        NotifyMssg = 'No ha seleccionado un archivo para cargar';
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'I');

    } else {
        for (const file of curFiles) {
            nameFile = file.name;

            if (valid_FileTypeToCharge(file)) {
                description = `Nombre: ${nameFile}`;
                meta = `Archivo:<br>${file.type} [${get_SizeFileToCharge(file.size)}]`;
                // const _img = document.createElement('img');
                // _img.src = URL.createObjectURL(file);
                // _li.appendChild(_img);
                
                // TitleBtn.classList.replace('bx--btn--tertiary','bx--btn--primary');

            } else {
                description = `Nombre: ${nameFile}`;
                meta = `Archivo:<br>${file.type} [Extención no valida]`;

                NotifyMssg = 'Archivo con extensión invalida '+ nameFile;
                NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'I');
            }

        }
    }

    LabelMessage.innerHTML = description;
    TitleBtn.innerHTML = meta;
}
