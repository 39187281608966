// File Single Upload 

if (document.querySelectorAll('.core_file_upload')) {

    let core_file_upload = document.querySelectorAll('.core_file_upload');
    // console.log('lenght: ' + core_file_upload.length);
    
    core_file_upload.forEach(inputFile=>{
        inputFile.addEventListener('change', function (e) {
            let block = e.target.dataset.block;
            // console.log('block: '+e.target.dataset.block);
            // let label = inputFile.parentElement;
            let span = document.querySelectorAll('.title_btn_load_file');
            let label = document.querySelectorAll('.info_namefile_load');

            get_InfoFileToCharge(inputFile, span[block], label[block]);                        
        })
    });

    // let file_uploader = document.getElementById('file_uploader');
    // let label_file = document.getElementById('label_file');

    // file_uploader.addEventListener('change', function (e) {

    //     // let filelenght = this.files.length;
    //     //     console.log('filelenght', filelenght);

    //     // if (filelenght >3) {
    //     //     NotifyMssg = 'Solo se pueden registrar 3 evidencias' + Prefix;
    //     //     NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'I');
    //     // }

    //     let file_state = document.querySelectorAll('.bx--file__state-container');

    //     file_state.forEach(element => {
    //         // console.log(element);
    //         element.innerHTML = '<button class="bx--file-close" type="button" aria-label="close"><svg aria-hidden="true" viewBox="0 0 16 16" width="16" height="16"><path fill="#231F20" d="M12 4.7l-.7-.7L8 7.3 4.7 4l-.7.7L7.3 8 4 11.3l.7.7L8 8.7l3.3 3.3.7-.7L8.7 8z"></path></svg></button>';
    //     });

    //     label_file.innerText = 'Se han cargado ' + file_state.length.toString() + ' Archivos de 3.';

    //     update_FileImageToCharge();
    // })

}
