//
function focus_scroll(element) {

    element.focus();

    element.scrollIntoView({
        block:'center',
        behavior:'smooth'
    });
}
