//
function map_ToolBarButtons() {

    document.querySelectorAll('[data-toolbar]')
        .forEach(e => e.addEventListener('click', function (e) {

            let selectCheck = e.target;
            OptionalAction_ToolBar(selectCheck);

            let xtoolbar = document.getElementById('maxtoolbar');
            xtoolbar.classList.remove('hidden_toolbar');
            xtoolbar.classList.add('show_toolbar');

        }));
}
