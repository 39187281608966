//
async function unsubscribePush() {
 
    const reg = await navigator.serviceWorker.getRegistration();
    const subscription = await reg.pushManager.getSubscription();
 
    if (subscription) {
        await subscription.unsubscribe();
        await fetch(`https://sipmcore.com/push_unsubscribe/${subscription.endpoint}`, {method:' DELETE '});

    } else {
        // already subscribed
    }
 
}
