//
function get_nfo_file(eimput) {
    let filenam = eimput.value;
    filenam = filenam.replace(/\\/g, '/').replace(/.*\//, '');
    document.getElementById('file_iname').innerHTML = filenam;
}

function get_nfo_img(eimput) {
    let filenam = eimput.value;
    filenam = filenam.replace(/\\/g, '/').replace(/.*\//, '');
    document.getElementById('img_iname').innerHTML = filenam;
}
