// Fetch Consume Progressive Load
function consume(reader) {
    let total = 0

    return new Promise((resolve, reject) => {
        function pump() {
            reader.read()
                .then(({done, value}) => {
                    if (done) {
                        resolve();
                        return;
                    }
                    
                    total += value.byteLength;
                    console.info(`recibido ${value.byteLength} bytes (${total} bytes en total)`);
                    pump();
                })
                .catch(reject);
        }
        pump();
    })
}
  
//   fetch("/music/pk/altes-kamuffel.flac")
//     .then(res => consume(res.body.getReader()))
//     .then(() => log("consumed the entire body without keeping the whole thing in memory!"))
//     .catch(e => log("something went wrong: " + e))
