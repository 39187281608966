//
function mapping_buttons_toolbar_pro() {
    console.info('%c ···'+'%c ToolBar Active', 'color:cyan;', 'color:#555;'); 

    document.querySelectorAll('[data-toolbar]')
        .forEach(e => e.addEventListener('click', function (e) {

            let imput_radio = e.target;
            input_radio_structured_list_click(imput_radio);

            let xtoolbar = document.getElementById('panel_tbp');
            xtoolbar.classList.remove('hidden_toolbar');
            xtoolbar.classList.add('show_toolbar');

        }));
}
