// ERROR CONTROL
// - Catch control
function InputController(err) {
    console.group('%c  Check Input Controller               ', 'background-color:#bbb000;color:#000;');

    if (typeof err === 'string') {
        console.info(err.toUpperCase());

    } else {
        switch (true) {        
            case err instanceof ExceptionInput:
                console.info(err.name);
                console.info(err.cause);
                break;
    
            default:
                console.error(err.name);
                break;
        }
    
        console.info(err.message);
        // console.warn(err.stack); Use Mozilla
    }

    console.groupEnd();  
}
