//
function check_input_checkbox(
    element: object,
    message:string ='',
    title:string=null,
    option=null,
    context:any=null
) {
    let detect:boolean =false;
    let flagtype:string ='I';
    // let dataValue = element.value;

    switch (true) {
        // case (!element.validity.valid):
        //     // for clean error message  
        //     detect = true;          
        //     break;

        case (!element.checked):
            detect = true;
        break
    }

    if (detect) {
        NotifyerPopUp.create(message + Prefix, title, flagtype);
        focus_scroll(element);
        throw new ExceptionInput('Check Checkbox', element, message);
    }

    return detect;
}
