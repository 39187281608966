// Notifyer PopUp

let NotifyerPopUp = {
    
    container: null,
    divTransition: null,
    process: true,
    message: null,
    title: null,
    role: null,
    enlapsed: 8000,

    svginfo: '',
    svgerror: '<svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" class="bx--toast-notification__icon" width="20" height="20" viewBox="0 0 20 20" aria-hidden="true"><path d="M10 1c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm3.5 13.5l-8-8 1-1 8 8-1 1z"></path><path d="M13.5 14.5l-8-8 1-1 8 8-1 1z" data-icon-path="inner-path" opacity="0"></path></svg>',
    svgsuccess: '<svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" class="bx--toast-notification__icon" width="20" height="20" viewBox="0 0 20 20" aria-hidden="true"><path d="M10 1c-4.9 0-9 4.1-9 9s4.1 9 9 9 9-4 9-9-4-9-9-9zM8.7 13.5l-3.2-3.2 1-1 2.2 2.2 4.8-4.8 1 1-5.8 5.8z"></path><path d="M8.7 13.5l-3.2-3.2 1-1 2.2 2.2 4.8-4.8 1 1-5.8 5.8z" data-icon-path="inner-path" opacity="0"></path></svg>',
    svgwarning: '<svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" class="bx--toast-notification__icon" width="20" height="20" viewBox="0 0 20 20" aria-hidden="true"><path d="M10 1c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm-.8 4h1.5v7H9.2V5zm.8 11c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"></path><path d="M9.2 5h1.5v7H9.2V5zm.8 11c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" data-icon-path="inner-path" opacity="0"></path></svg>',
    svgaspad: '<svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" class="bx--toast-notification__close-icon" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true"><path d="M12 4.7l-.7-.7L8 7.3 4.7 4l-.7.7L7.3 8 4 11.3l.7.7L8 8.7l3.3 3.3.7-.7L8.7 8z"></path></svg>',

    create: function (
        message:string, 
        title:string='SIEMENS IPM CORE', 
        role:string='I',
        button:string=''
        ) {

        if (NotifyerPopUp.container === null) {
            this.container = document.getElementById('flash_alert');
        }

        this.process = false;

        let divTransition = document.createElement('div');
            divTransition.classList.add('wide_notify');
            divTransition.classList.add('box__shadow');
            divTransition.style.opacity = '1';
            divTransition.style.display = '';
            divTransition.innerHTML = '';

        role = role.toUpperCase();
        switch (role) {
            case 'D':
            case 'E': divTransition.classList.add('alert-danger'); break;
            case 'O':
            case 'M': divTransition.classList.add('alert-markok'); break;
            case 'S': divTransition.classList.add('alert-success'); break;
            case 'A':
            case 'W': divTransition.classList.add('alert-warning'); break;
            case 'I':
            default: divTransition.classList.add('alert-info'); break;
        }

        this.container.appendChild(divTransition);

        let divIcon = document.createElement('div');
            divIcon.classList.add('ico_ntfy');
        divTransition.appendChild(divIcon);

            let iFont = document.createElement('i');
                iFont.classList.add('ms-Icon');
            divIcon.appendChild(iFont);

        let divDesc = document.createElement('div');
            divDesc.classList.add('WideBannerDescription');
            divDesc.classList.add('flash');
        divTransition.appendChild(divDesc);

            let h4Title = document.createElement('h4');
                h4Title.innerText = title;
            divDesc.appendChild(h4Title);

            let pMessage = document.createElement('p');
                pMessage.innerText = message;
            divDesc.appendChild(pMessage);

            let smallAspad = document.createElement('small');
                smallAspad.innerHTML = '';
                // this.svgaspad;
            divDesc.appendChild(smallAspad);

        if (button=='npush'){
            this.enlapsed=60000;

            let buttonSet = document.createElement('button');
            buttonSet.id = 'btn_check_notifications_push';
            buttonSet.classList.add('bx--btn');
            buttonSet.classList.add('bx--btn--primary');
            buttonSet.classList.add('bx--btn--sm');
            buttonSet.innerText = 'Configurar';
            divDesc.appendChild(buttonSet);

            enable_btn_check_notifications_push();
        }

        this.divTransition = divTransition;
        NotifyerPopUp.timer(divTransition);
    },
    
    connection: function (
        role:string='offline',
        message:string=''
        ) {

        if (NotifyerPopUp.container === null) {
            this.container = document.getElementById('flash_alert');
        }

        this.process = false;

        let divTransition = document.createElement('div');
        divTransition.classList.add('wide_notify');
        divTransition.classList.add('box__shadow');
        divTransition.style.opacity = '1';
        divTransition.style.display = '';
        divTransition.innerHTML = '';

        role = role.toLowerCase();
        switch (role) {
            case 'online':
                divTransition.classList.add('wifi-success');
                message='Plataforma Online';
                break;

            case 'offline':
                divTransition.classList.add('wifi-warning');
                message='Plataforma Offline';
                break;
                
            default:
                divTransition.classList.add('alert-info');
                break;
        }

        this.container.appendChild(divTransition);

        let divIcon = document.createElement('div');
            divIcon.classList.add('ico_ntfy');
        divTransition.appendChild(divIcon);

            let iFont = document.createElement('i');
                iFont.classList.add('ms-Icon');
            divIcon.appendChild(iFont);

        let divDesc = document.createElement('div');
            divDesc.classList.add('WideBannerDescription');
            divDesc.classList.add('flash');
        divTransition.appendChild(divDesc);

            let h4Title = document.createElement('h4');
                h4Title.innerText = 'SIEMENS IPM CORE';
            divDesc.appendChild(h4Title);

            let pMessage = document.createElement('p');
                pMessage.innerText = message;
            divDesc.appendChild(pMessage);

            let smallAspad = document.createElement('small');
                smallAspad.innerHTML = '';
                //this.svgaspad;
            divDesc.appendChild(smallAspad);

        this.divTransition = divTransition;
        NotifyerPopUp.timer(divTransition);
    },

    timer: function (block) {
        let ztime = setTimeout(function () {
            NotifyerPopUp.hidden(block);
        }, this.enlapsed);
    },
    
    hidden: function (mblock) {
        let that = mblock;
        let process = this.process

        that.style.opacity = 1;
        process = true;

        (function FadeOutNotify() {
            if ((that.style.opacity -= .01) < 0) {
                that.style.display = 'none';
                that.parentNode.removeChild(that);

            } else {
                if (process) {
                    requestAnimationFrame(FadeOutNotify);
                }
            }
        })();
    }
}
