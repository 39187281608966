//
function TileIndicator_secuence(
    qty:number, 
    indicator:object,
    classBase:string='mask_blue'
) {    
    if (indicator) {
        
        let witness = 0;
        let digit = indicator.querySelectorAll('.counter');
        digit[0].innerHTML = witness.toString();

        let id_dp = setInterval(()=>{
            if (qty == witness) {
                clearInterval(id_dp);
        
                indicator.classList.remove('mask_green');
                indicator.classList.remove('mask_blue');
                indicator.classList.add(classBase);
        
            } else {
                witness++;
                digit[0].innerHTML = witness.toString();
            }
        }, 50);

    }
}
