//
async function subscribe_Sw(){
    let sw = await navigator.serviceWorker.ready;
    let push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: 'SIEMENS_IPM_CORE'
    });

    console.info(JSON.stringify(push));
}
