//** Convert Language */
if (document.getElementById('setting_language')) {
    let setting_language = document.getElementById('setting_language');

    setting_language.addEventListener('click', function (e) {
        let action = e.target.dataset.lang;

        switch (action) {
            case 'DE': break;
            case 'ES': break;
            case 'EN': break;

            default: break;
        }

        //console.log('language: ' + action);
    })

}

// Language
function set_language(lang, locat) {
    let regstart = new Date().getTime();

    let setlang: string = '';
    let messageLang: string = '';
    let valid = false;
    let frmData = new FormData;

    frmData.append('lang', lang);
    frmData.append('locat', locat);

    switch (lang) {
        case 'ES':
            // spanish
            messageLang = 'Traducción al Español';
            valid = true;
            break;

        case 'EN':
            // english
            messageLang = 'English translation';
            valid = true;
            break;

        case 'DE':
            // german
            messageLang = 'Deutsche Übersetzung';
            valid = true;
            break;

        default:
            return false;
    }

    if (setlang == lang) {
        valid = false;
    } else {
        setlang = lang;
    }

    if (valid) {
        let xpath = '/System/Service/Language/TraductorPage';

        const request = new Request(xpath, {
            method: 'POST',
            body: frmData,
            credentials: 'include',
            mode: 'same-origin',
        });

        fetch(request)
            .then(function (res) {
                //console.info('status: ' + res.status);
                return res.json();
            })
            .then(function (data) {
                //console.log('data: ' + data);
                //console.log('length: ' + data.length);

                if (data.length > 4) {
                    for (let ndx = 0; ndx < data.length; ndx++) {
                        let jkey = data[ndx];

                        if (document.getElementById(jkey['LA_TAGREF'])) {
                            document.getElementById(jkey['LA_TAGREF']).innerHTML = jkey['LA_TRADUCTION'];
                        }
                    }
                }

                let regload = new Date().getTime();
                //console.info('Time Load: ' + ((regload - regstart) / 60).toFixed(2) + ' s');
            })
            .catch(err => {
                console.error('ERR: ' + err.name);
                console.error('ERR: ' + err.message);
                console.error('ERR: ' + err.toString());
                console.error('ERR: ' + err.stack);
            });

    }
}

if (document.getElementsByClassName('set-lang')
    && document.getElementById('InputLevelPage')) {
    const setlang = document.querySelectorAll('.set-lang');
    const lpage = document.getElementById('InputLevelPage').value;

    for (let la = 0; la < setlang.length; la++) {

        setlang[la].addEventListener('click', function (e) {
            set_language(e.target.dataset.lang, lpage);
        });
    }
}
