//

function valid_FileTypeToCharge(file) {
    const fileTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf"
    ];

    return fileTypes.includes(file.type);
}
