//
const baseurl = 'https://127.0.0.1:3030/';

function check_server_live() {
    let output = document.getElementById('output');
    
    fetch(baseurl)
        .then((res)=>res.json())
        .then((content)=>{
            output.innerHTML = JSON.stringify(content)
        })
        .catch((err)=>console.error);
}

function request_token(ev) {
    let output = document.getElementById('output');
    
    fetch(baseurl + 'token')
        .then((res)=>res.json())
        .then((content)=>{
            output.innerHTML = JSON.stringify(content)

            let token = content.data;
            localStorage.setItem('token', token);
        })
        .catch((err)=>console.error);
}

function send_token_and_secret(ev) {
    //send the token and for some secret purpose
    let output = document.getElementById('output');
    let token = localStorage.getItem('token');
    let url = baseurl + 'test';

    if (!token) {
        output.innerHTML = 'No available token';
        return;
    }

    let h = new Headers();
    h.append('Authorization', `Bearer ${token}`);
    
    let req = new Request(url, {
        headers: h,
        method: 'GET',
    });

    fetch(req)
        .then((res) => res.json())
        .then((content) => {
            output.innerHTML = JSON.stringify(content);
            if (content.code > 0) {
            //error happened
            localStorage.removeItem('token');
            }
        })
        .catch((err)=>{
            FetchController(err);
        });
  }
