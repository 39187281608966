// func example
function click_sound (reference_sound='click') {
    let path_sound ='';

    switch (reference_sound) {
        case 'click':
            path_sound ='/var/www/';
            break;
    
        default:
            break;
    }

    let run_sound = document.getElementById('run_sound');
    run_sound?.setAttribute('src', path_sound);
    run_sound?.play();
}
