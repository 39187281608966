// Convert String
// Base64 versus UTF8

function utf8_to_b64(StringToConvert :string) {
    return window.btoa(unescape(encodeURIComponent(StringToConvert)));
}

function b64_to_utf8(StringToConvert :string) {
    return decodeURIComponent(escape(window.atob(StringToConvert)));
}

const DeveloperTeam: string = b64_to_utf8('RGVwbG95ZWQgYnkgU0lFTUVOUyBTLkEuIFtMREEgQU0gQ0wgSVBNXSBEZXZlbG9wZXIgVGVhbSBbREVWIFNXIFNJQ0xdIEVkd2luIFBpemFycm8gQXJheWEsIOKAoCBEaWVnbyBNb2xpbmEgU2Fleg==');
