//** INDICATORS LINK */

if (document.querySelector('[data-indicator]')) {
    const indicator_stage = document.querySelectorAll('[data-indicator]');

    for (const indicator of indicator_stage) {
        indicator.addEventListener('click', function (event) {

            let action = event.target.dataset.indicator;
            let xpath = '';
            let optionHref = true;
            AnimationWallProgress(true);

            switch (action) {
                // COMERCIAL PROJECT
                //

                // CONTRACT MANAGEMENT - ADMINISTRACIÓN DEL CONTRATO
                case 'DesviationsOutDate': 
                    xpath = '/ContractManagement/Command/Findings/ReportDesviationsOutDate'; 
                    break;

                case 'FindingsOutDate':
                    // xpath = '/ContractManagement/Command/Findings/ReportFindingsOutDate';
                    xpath = '/ProcessAssurance/Command/Findings/DeskControlContract';
                    break;
                //

                // CONTRACT PERFONMANCE - GESTION DESEMPEÑO DEL CONTRATO
                //

                // DEVELOPMENT
                case 'BigPicture': 
                    xpath = '/Development/Command/Dashboard/Project'; 
                    optionHref = false; 
                    break;
                //

                // DOCUMENT MANAGEMENT SYSTEM - SISTEMA GESTION DOCUMENTAL
                case 'WaitDocument': 
                    xpath = '/DMS/Command/Approvals/SignatureWaitDocument'; 
                    break;
                //

                // EHSQ - GESTION DE EHSQ
                //

                // PMPI - profile management and personal information
                //

                // EQINSPECTOR
                case 'EQIWaitValidationInspection': 
                    xpath = 'DMS/Command/Approvals/SignatureWaitDocument'; 
                    break;
                
                case 'manager_vulnerabilities_wait':
                    document.getElementById('manager_vulnerabilities_wait').scrollIntoView();
                    break;
                //
                
                // EXECUTION SERVICE - EJECUCIÓN DEL SERVICIO
                //
                
                // HUMAN RESOURCES - GESTION EN RECURSOS HUMANOS
                //

                // LOGIGISTIC - GESTION LOGISTICA
                //
                
                // PLANNING - GESTIÓN PROGRAMACIÓN
                //
                
                // PROCESS ASSURANCE - ASEGURAMIENTO DE GESTION POR PROCESOS
                case 's01': document.getElementById('s01').scrollIntoView(); break;
                case 's02': document.getElementById('s03').scrollIntoView(); break;
                case 's03': document.getElementById('s01').scrollIntoView(); break;
                case 's04': document.getElementById('s02').scrollIntoView(); break;
                case 's05': document.getElementById('s03').scrollIntoView(); break;
                //
                
                // QRTRACKING
                //

                // QUALITY MANAGEMENT - ASEGURAMIENTO DE QA/QC
                case 'ResponsibleFindings': 
                    xpath = '/PMPI/Command/Findings/DeskMyEvents'; 
                    break;
                //

                // REGISTERS EVIDENCE
                //

                // RELIABILITY - CONFIABILIDAD
                //
                
                // RISK MANAGEMENT - GESTION DEL RIESGO
                case 'MyIncidentsCMDIC': 
                    xpath = '/RiskManagement/Command/collahuasi/UserIncidents'; 
                    break;
                //

                // STAFF RECRUITMENT
                //

                // SYSTEM
                //

                // TICA - TECHNOLOGY INFORMATION COMUNICATION AND AUTOMATION
                //

                // TOOLTIME
                //

                // WORKPACKS
                //
            }

            if (xpath != '') {
                if (optionHref) {
                    window.location.href = xpath;
                } else {
                    window.open(xpath, '_blank');
                    AnimationWallProgress(false);
                }

            } else {
                AnimationWallProgress(false);
            }

        });

    }

}
