// gps coordinates
async function gps_coordinates() {

    if ("geolocation" in navigator) {

        navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {

                const latitude: number = position.coords.latitude;
                const longitude: number = position.coords.longitude;
                const altitude = position.coords.altitude;
                const altitudeAccuracy = position.coords.altitudeAccuracy;
                const accuracy: number = position.coords.accuracy;

                document.getElementById('gps_latitude').value = latitude.toString();
                document.getElementById('gps_longitude').value = longitude.toString();
                document.getElementById('gps_altitude').value = altitude?.toString();
                document.getElementById('gps_altitudeAccuracy').value = altitudeAccuracy?.toString();
                document.getElementById('gps_accuracy').value = accuracy.toString();

                console.log(latitude + ", " + longitude + ", " + accuracy);

            },
            (error: GeolocationPositionError) => {
                
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        console.error("User denied the request for Geolocation.");

                        setTimeout(function () {
                            NotifyerPopUp.create('Debe Permitir el Acceso al GPS del dispositivo', 'SIEMENS IPM CORE', 'W');

                            gps_coordinates();
                        }, 10000);

                        break;
                    case error.POSITION_UNAVAILABLE:
                        console.error("Location information is unavailable.");
                        break;
                    case error.TIMEOUT:
                        console.error("The request to get user location timed out.");
                        break;
                    // case error.UNKNOWN_ERROR:
                    default:
                        console.error("An unknown error occurred:", error.code);
                        break;
                }
            }
        );

    } else {
        console.error("Geolocation is not available in this browser.");
    }
}

if (document.getElementById('gps_coordinates')) {
        
    let gps_data = document.getElementById('gps_coordinates');

    let input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('value', '');
    input.setAttribute('id', 'gps_latitude');
    gps_data?.appendChild(input);

    input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('value', '');
    input.setAttribute('id', 'gps_longitude');
    gps_data?.appendChild(input);

    input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('value', '');
    input.setAttribute('id', 'gps_altitude');
    gps_data?.appendChild(input);

    input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('value', '');
    input.setAttribute('id', 'gps_altitudeAccuracy');
    gps_data?.appendChild(input);

    input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('value', '');
    input.setAttribute('id', 'gps_accuracy');
    gps_data?.appendChild(input);

    gps_coordinates();

    // while (true) {
    //     if ("geolocation" in navigator) {
    //         break;
    //     }
    // }
}
