//** BANNER QUESTION */
if (document.getElementById('bannerQuestion')) {

    document.getElementById('question_btn_close')
    ?.addEventListener('click', function (e) {
        AnimationWallProgress(false);

        document.getElementById('bannerQuestion').style.display = 'none';
    })
    
}
