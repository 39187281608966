//

function check_input_select_one(
    element:object,
    message:string ='',
    title:string=null,
    option=null,
    context:any=null
) {
    let detect:boolean =false;
    let flagtype:string ='I';
    let optionselect = element.options[element.selectedIndex].value;
    
    if (element.validity.valid) {
        // for clean error message
    }

    if (optionselect == '') {
        detect = true;

    } else if (Array.isArray(option)) {

    }

    if (detect) {
        NotifyerPopUp.create(message + Prefix, title, flagtype);
        focus_scroll(element);
        throw new ExceptionInput('Check Select One', element, message);
    }

    return detect;
}
