// Element Secure
function create_InputTextHidden(
    formCurrent:object,
    NameElement:string,
    ElementOrigine:object
) {
    let hinput = document.createElement('input');
    hinput.name = NameElement;
    hinput.type = 'hidden';
    hinput.value = ElementOrigine.value.trim();

    formCurrent.appendChild(hinput);
    ElementOrigine.value = '';
}
