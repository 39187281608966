// Element Input Password Secure
function create_InputPasswordHidden(
    formCurrent:object,
    NameElement:string,
    ElementOrigine:object
) {
    let hinput = document.createElement('input');
    hinput.name = NameElement;
    hinput.type = 'hidden';
    hinput.value = hex_sha512(ElementOrigine.value.trim());

    formCurrent.appendChild(hinput);
    ElementOrigine.value = '';
}
