function getDateFromString(stringDate:string, short:boolean=true) {

	let nDate = new Date(stringDate);
	let options;

	if (short) {
		options = {year:'numeric', month:'2-digit', day:'2-digit' };
	} else {
		options = {year:'numeric', month:'2-digit', day:'2-digit', hour:'2-digit', minute:'2-digit' };
	}

	let formatDate = nDate.toLocaleString('es-CL', options);

	// let nMonth = nDate.getMonth() +1;
	// let nDay = nDate.getDay();

	// if (short) {
	// 	formatDate = `${nYear}-${nMonth}-${nDay}`;
	// } else {
	// 	let nHours = nDate.getHours();
	// 	let nMins = nDate.getMinutes();

	// 	formatDate = `${nYear}-${nMonth}-${nDay} ${nHours}:${nMins}`;
	// }

	return formatDate;
}
