//
function check_input_number(
    element:object,
    message:string ='',
    title:string='',
    option=null
) {
    let detect:boolean =false;
    let flagtype:string ='I';
    let dataValue = element.value;
    
    switch (true) {
        // case (!element.validity.valid):
        //     // for clean error message  
        //     detect = true;          
        //     break;

        case (element.value ==0):
            message = 'El registro se encuentra en blanco o no se ingreso un número';
            detect = true;
        break

        case (element.validity.valueMissing):
            message = 'El registro no concuerda como valor número';
            detect = true;
        break
    }

    if (Array.isArray(option)) {

        switch (true) {
        
            case option.includes('downzero'):
                message = 'check menor a cero';
                break;
        
            case option.includes('upzero'):
                message = 'check mayor a cero';
                break;
        }        
    }

    if (detect) {
        NotifyerPopUp.create(message + Prefix, title, flagtype);
        focus_scroll(element);

        throw new ExceptionInput('Check Text', element, message);
    }

    return detect;
}
