//
function check_input_textarea(
    element:object,
    message:string ='',
    title:string=null,
    option=null,
    context:any=null
) {
    let detect:boolean =false;
    let flagtype:string ='I';
    let dataValue = element.value;
    
    switch (true) {
        // case (!element.validity.valid):
        //     // for clean error message  
        //     detect = true;          
        //     break;

        case (dataValue ==''):
            detect = true;
        break
    }

    if (Array.isArray(option)) {

    }

    if (detect) {
        NotifyerPopUp.create(message + Prefix, title, flagtype);
        focus_scroll(element);
        
        throw new ExceptionInput('Check Text', element, message);
    }

    return detect;
}
