//
function check_notification() {
    let permiss = false;

    // Let's check if the browser supports notifications
    if ('Notification' in window) {

        if (Notification.permission === 'granted') {
            permiss = true;

        } else {
            if (Notification.permission !== 'denied') {
                
            }

            Notification.requestPermission().then((status)=> {
                if (status === 'granted') {
                    permiss = true;
                }
            });
        }

    } else {
        console.info('%c SIPM CORE: Device no support desktop notification', 'color:#32549e');
    }

    return permiss;
}

function get_PopUpRequestEnablePush(stat_push) {

    if (!('Notification' in window)) {
        console.info('SIPM CORE: Device no support desktop notification');
        stat_push.innerHTML = 'Off';
    
    } else {
        if (Notification.permission === 'granted') {
            stat_push.innerHTML = 'On';
        } else {
            setTimeout(() => {
                let t0 = 'SIEMENS IPM CORE';
                let m0 ='Favor de habilitar notificaciones para funcionalidad completa de nuestra plataforma';
                NotifyerPopUp.create(m0, t0, 'I', 'npush');

            }, 3000);
    
        }
    }
}

function enable_btn_check_notifications_push() {

    if (document.getElementById('btn_check_notifications_push')) {
        
        document.getElementById('btn_check_notifications_push')
        .addEventListener('click', (event)=>{
            // Process Settings Notification
            console.log('click btn')

            if (window.Notification) {
                console.info('support window notifications ', Notification.permission);
                Notification.requestPermission();
            }

            Notification.requestPermission().then((status) => {
                if (status === 'granted') {
                    console.log('Notification User accepted');
                }
            });
        });

    } else {
        console.log('no btn')
    }
}

if (document.getElementById('text_stat_notification')) {
    let stat_push = document.getElementById('text_stat_notification');
    
    // get_PopUpRequestEnablePush(stat_push);

    stat_push.addEventListener('click', () => {
        console.log('click btn stat');

        // send_NotificationToast(
        //     'SIEMENS IPM CORE Identity', 
        //     'Notificaciones Activas',
        //     'info'
        //     );
    })
}
