//
function moveScreen(x, y) {

    let boxscrolling = document.getElementById('boxscrolling');

    if (y == 0) {
        y = boxscrolling.scrollTop;
    }

    if (x == 0) {
        x = boxscrolling.scrollLeft;
    }

    boxscrolling.scrollTo({
        top: y,
        left: x,
        behavior: 'smooth'
    });

    //console.log(x.toString() + ' / ' + y.toString());
}

if (document.getElementById('toolNavigator')) {
    let toolcircleup = document.getElementById('toolcircleup');
    let toolcircleright = document.getElementById('toolcircleright');
    let toolcirclebottom = document.getElementById('toolcirclebottom');
    let toolcircleleft = document.getElementById('toolcircleleft');

    let repeater;
    let boxscrolling = document.getElementById('boxscrolling');
    let step = 40; // px
    let timer = 10; // ms

    toolcircleup.addEventListener('mouseover', function (e) {
        repeater = setInterval(() => {
            let y = boxscrolling.scrollTop - step;
            moveScreen(0, y)
        }, timer);
    })
    toolcircleup.addEventListener('mouseout', function (e) { clearInterval(repeater); })

    toolcirclebottom.addEventListener('mouseover', function (e) {
        repeater = setInterval(() => {
            let y = boxscrolling.scrollTop + step;
            moveScreen(0, y)
        }, timer);
    })
    toolcirclebottom.addEventListener('mouseout', function (e) { clearInterval(repeater); })

    toolcircleright.addEventListener('mouseover', function (e) {
        repeater = setInterval(() => {
            let x = boxscrolling.scrollLeft + step;
            moveScreen(x, 0)
        }, timer);
    })
    toolcircleright.addEventListener('mouseout', function (e) { clearInterval(repeater); })

    toolcircleleft.addEventListener('mouseover', function (e) {
        repeater = setInterval(() => {
            let x = boxscrolling.scrollLeft - step;
            moveScreen(x, 0)
        }, timer);
    })
    toolcircleleft.addEventListener('mouseout', function (e) { clearInterval(repeater); })

}
