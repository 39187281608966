// Catch Error Control
function ErrorController(err) {
    console.group('%c  Error Controller               ', 'background-color:#bbb000;color:#000;');

    if (typeof err === 'string') {
        console.info(err.toUpperCase());

    } else {
        switch (true) {
            case err instanceof EvalError:
                console.warn('Eval Error');
                break;
            case err instanceof RangeError:
                console.warn('Range Error');
                break;
            case err instanceof ReferenceError:
                console.warn('Reference Error');
                break;
            case err instanceof SyntaxError:
                console.warn('Syntax Error');
                break;
            case err instanceof URIError:
                console.warn('URI Error');
                break;
        
            case err instanceof ExceptionInput:
                console.warn('Exception Input');
                break;
        
            case err instanceof TypeError:
            default:
                console.error(err.name);
                break;
        }
    
        console.info(err.message);
    }

    console.info('%c - EOM ---------------------------', 'color:#bbb000;');
    console.groupEnd();  
}
