//
async function subscribeToPush() {
    
    if (navigator.serviceWorker) {
 
        const reg = await navigator.serviceWorker.getRegistration();
 
        if (reg && reg.pushManager) {
 
            const subscription = await reg.pushManager.getSubscription();
 
            if (!subscription) {
 
                const key = await fetch('https://sipmcore.com/vapid_key');
                const keyData = await key.text();
 
                const under = await reg.pushManager.subscribe({
                    applicationServerKey: keyData,
                    userVisibleOnly: true
                });
 
                await fetch('https://sipmcore.com/push_subscribe', {
                    method: 'POST',
                    headers: {'Content-Type': 'application / json'},
                    body: JSON.stringify({
                        endpoint: sub.endpoint,
                        expirationTime: sub.expirationTime,
                        Keys: sub.toJSON().Keys
                    })
                });
 
            }
 
        }
 
    }
}
