//
class ExceptionInput extends Error
{
    public cause:string;

    constructor(
        name:string, 
        element:object =null, 
        cause:string ='...')
    {
        super(name);
        this.name = name;

        this.message = cause;
        if (element) {
            this.message = 'Input > ' + element.name.toString();
        }

        this.cause  = 'Cause > ' + cause;
    }
}
