//

function get_SizeFileToCharge(
    number
) {

    if (number < 1024) {
        return number + 'Bytes';
        
    } else if (number >= 1024 && number < 1048576) {
        return (number / 1024).toFixed(1) + 'Kb';

    } else if (number >= 1048576) {
        return (number / 1048576).toFixed(1) + 'Mb';

    } else if (number >= 1073741824) {
        return (number / 1073741824).toFixed(1) + 'Gb';

    }
}
