// WIDE BANNER
// Banner Full Screen Waiting
//
function hidden_wide_banner() {
    let wbnn = document.getElementById('wide_banner');
    wbnn.style.opacity = '1';

    (function FadeOutBanner() {
        let setopacity = parseFloat(wbnn.style.opacity);
        setopacity -= 0.01;

        if (setopacity < 0) {
            wbnn.style.display = 'none';
        } else {
            wbnn.style.opacity = setopacity.toString();
            requestAnimationFrame(FadeOutBanner);
        }
    })();
}

// Auto Hidden Wide Banner
if (document.getElementById('wide_banner')) {
    setTimeout(function () { 
        hidden_wide_banner() 
    }, 8000);
}
