if (document.getElementById('name_profile')) {
    let name_profile = document.getElementById('name_profile');

    name_profile.addEventListener('click', function (e) {

        let action = e.target.dataset.user;
        let xpath = '';

        switch (action) {
            case 'myprofile':
                xpath = '/PMPI/Command/Manager/Home';
                break;

            default:
                xpath = '';
                break;
        }

        console.info('name_profile : ' + xpath);
        window.location.href = xpath;
    })
}
