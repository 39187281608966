//** HOME SUBDOMAIN BUTTONS */
if (document.querySelector('[data-card]')) {
    const selmenu = document.querySelectorAll('[data-card]');

    for (const submenu of selmenu) {
        submenu.addEventListener('click', function (event) {

            let action = event.target.dataset.card;
            // console.log('action: ', action);

            let xpath = ''; // /System/Command/Core/Home
            let optionHref = true;

            if (event.target.dataset.process) {

                const elementGroup = document.querySelector('.' + event.target.dataset.process);
                elementGroup.classList.add('blockHidden');

                if (document.querySelector('[data-submodule="' + action + '"]')) {

                    const submodule = document.querySelector('[data-submodule="' + action + '"]');
                    submodule.classList.add('blockVisible');
                    //let nameSubModule = submodule.dataset.name;

                    submodule.addEventListener('click', function (e) {
                        // normalize
                        submodule.classList.remove('blockVisible');
                        elementGroup.classList.remove('blockHidden');
                    })
                }
            }

            AnimationWallProgress(true);

            switch (action) {
                // COMERCIAL PROJECT
                //

                // CONTRACT MANAGEMENT - ADMINISTRACIÓN DEL CONTRATO
                //

                // CONTRACT PERFONMANCE - GESTION DESEMPEÑO DEL CONTRATO
                //

                // DEVELOPMENT
                case 'BigPicture':      xpath = '/Development/Command/Dashboard/Project'; optionHref = false; break;
                case 'DataDictionary':  xpath = '/Development/Command/Database/DataDictionary'; optionHref = false; break;

                case 'PlatformSelectorsDefine': xpath = '/Development/Command/Platform/ConfigSelectors'; break;

                case 'DatabaseQuery':   xpath = '/Development/Command/Database/ManagementAdmin'; optionHref = false; break;
                case 'TestingFunctions':xpath = '/Development/Command/Testing/ToolFunctions'; break;
                case 'IconsFabricUI':  xpath = '/Development/Command/BrandTool/Icons'; optionHref = false; break;
                case 'InformationFpm':  xpath = '/Development/Command/Server/InformationFpm'; optionHref = false; break;
                case 'TestCurl':        xpath = '/Development/Command/Testing/CurlHttps'; break;
                case 'EQInspectorLoader': xpath = '/Development/Command/EQInspector/DeskLoader'; break;
                //

                // DOCUMENT MANAGEMENT SYSTEM - SISTEMA GESTION DOCUMENTAL
                case 'ControlLogger': xpath = '/ControlDocument/ControlLogger/Main/Desk'; break;
                case 'RecordMeeting': xpath = '/System/Command/Lockeds/StopOnConstruction'; break;
                case 'RecordRittus':  xpath = '/System/Command/Lockeds/StopOnConstruction'; break;
                case 'CheckApprovations': xpath = '/DMS/Command/Approvals/SignatureWaitDocument'; break;
                //

                // EHSQ - GESTION DE EHSQ
                case 'RecordLessonsLeaning': xpath = '/EHSQ/Command/Lessons/RecordLearning'; break;
                //

                // PMPI - profile management and personal information
                //

                // EQINSPECTOR
                // case 'goEqInspectorBelts': xpath = '/EQInspector/Command/Manager/Home'; break;
                case 'goEqInspectorBelts':      xpath = '/EQInspector/Command/Manager/Belts'; break;
                case 'goEqiBelts_inspections':  xpath = '/EQInspector/Command/Belt/FormProtocol'; break;
                case 'goEqiBelts_changes':      xpath = '/EQInspector/Command/Belt/FormChange'; break;
                case 'goEqiBelts_mgmnt_operation':  xpath = '/EQInspector/Command/Belt/ManagerOperations'; break;
                case 'goEqiBelts_mgmnt_planner':    xpath = '/EQInspector/Command/Belt/ManagerPlanning'; break;
                case 'goEqiBelts_mgmnt_reliability': xpath = '/EQInspector/Command/Belt/ManagerReliability'; break;
                case 'goEqiBelts_report_conditions': xpath = '/EQInspector/Command/Belt/FormSendReport'; break;
                case 'goEqiBelts_bi':           xpath = '/EQInspector/Command/Belt/BIContract'; break;
                case 'goEqiBelts_historic':     xpath = '/EQInspector/Command/Belt/ReportHistoric'; break;
                
                case 'goHeatMap':     xpath = '/EQInspector/Command/Belts/ReportHeatMap'; break;

                case 'goEqiBelts_mgmnt_cmdic':  xpath = '/EQInspector/Command/Belt/ExecutiveCmdic'; break;
                case 'goEqiBelts_bi_cmdic':     xpath = '/EQInspector/Command/Belt/BIContract'; break;

                case 'goEqInspectorLiner':  
                    // xpath = '/EQInspector/Command/Manager/DeskPlates'; break;
                case 'goEqInspectorLubx':   
                    // xpath = '/EQInspector/Command/Manager/DeskLubrication'; break;
                case 'goEqInspectorHvacx':  
                    // xpath = '/EQInspector/Command/Manager/DeskVentilation'; break;
                    message_onbuilding();
                break;
                //
                
                // EXECUTION SERVICE - EJECUCIÓN DEL SERVICIO
                case 'ControlDetentions':   xpath = '/ExecutionService/Command/Detentions/DeskMonitor'; break;
                //
                
                // HUMAN RESOURCES - GESTION EN RECURSOS HUMANOS
                //

                // LOGIGISTIC - GESTION LOGISTICA
                //
                
                // PLANNING SHUTDOWN - GESTIÓN PROGRAMACIÓN SHUTDOWN
                case 'PlanningShutdown':    xpath = '/System/Command/Lockeds/StopOnConstruction'; break;
                case 'StorageDiskProg':    xpath = '/Planning/Command/DMS/DeskDiskShared'; break;
                //

                // PLANNING WEEKLY - GESTIÓN PROGRAMACIÓN SEMANAL
                case 'ReportPlanningIdlers': xpath = '/EQInspector/Command/Belt/ManagerPlanning'; break;
                case 'PlannerEqInsIdlers':  xpath = '/EQInspector/Command/Idlers/DeskPlanner'; optionHref = false; break;
                case 'PlannerEqInsPlates':  xpath = '/EQInspector/Command/Manager/Home'; optionHref = false; break;
                case 'PlannerEqInsLubrication': xpath = '/EQInspector/Command/Manager/Home'; optionHref = false; break;
                case 'PlannerEqInsIdlers':  xpath = '/EQInspector/Command/Manager/Home'; optionHref = false; break;
                case 'PrintWorkorder':      xpath = '/System/Command/Lockeds/StopOnConstruction'; break;
                case 'PlanningWeekly':      xpath = '/System/Command/Lockeds/StopOnConstruction'; break;
                case 'PrintLockCard':       xpath = '/System/Command/Lockeds/StopOnConstruction'; break;
                case 'DashboardMaintenanceTracker': xpath = '/Planning/Command/Dashboard/MaintenanceTracker'; break;
                //
                
                // PROCESS ASSURANCE - ASEGURAMIENTO DE GESTION POR PROCESOS
                //
                
                // QRTRACKING
                case 'QrTracking':          xpath = '/QRTracking/Command/Manager/Home'; break;
                //

                // QUALITY MANAGEMENT - ASEGURAMIENTO DE QA/QC
                //

                // REGISTERS EVIDENCE
                //

                // RELIABILITY - CONFIABILIDAD
                //

                // RISK MANAGEMENT - GESTION DEL RIESGO
                //

                // STAFF RECRUITMENT
                //

                // SYSTEM
                case 'ManagementAccounts':  xpath = '/System/Command/Accounts/ManagementProfiles'; break;
                //

                // TICA - TECHNOLOGY INFORMATION COMUNICATION AND AUTOMATION
                //

                // TOOLTIME
                case 'ContractTooltime':    xpath = '/ToolTime/Command/Manager/Home'; break;
                //

                // WORKPACKS
                case 'MaintenanceWorkpacks': xpath = '/WorkPacks/Command/Manager/Home'; break;
                //

                // QRTRACKING

                // TOOLTIME

                // TRANSFER CONTROL WORKERS
                case 'TransferControlManagement': xpath = '/TransferControl/Command/Manager/Home'; break;
                case 'TransferControlRecordsVehicle': xpath = '/TransferControl/Command/Manager/RecordsVehicle'; break;
                case 'TransferControlCapture': xpath = '/TransferControl/Command/Manager/Capture'; break;
                case 'TransferControlReport': xpath = '/TransferControl/Command/Manager/Reports'; break;

                // WORKPACKS


                //** PROCESOS DE PROVISION DE RECURSOS */
                // RISK MANAGEMENT - GESTION DEL RIESGO
                case 'RiskManagement': xpath = '/RiskManagement/Command/Manager/Home'; break;

                // MANAGEMENT HUMAN RESOURCES - GESTION DE RRHH
                case 'ContractCollaborators': xpath = '/HumanResources/Command/Collaborators/DeskCollaborators'; break;
                case 'NewCollaboratorContract': xpath = '/HumanResources/Command/Collaborators/DataCollaborator'; break;
                case 'NewPersonalRequirement': xpath = '/HumanResources/Command/StaffRequirement/NewRequirement'; break;
                case 'ControlPersonalRequirement': xpath = '/HumanResources/Command/StaffRequirement/ContractControl'; break;

                //MEDICAL LICENSES
                case 'NewMedicalLicenses': xpath = '/HumanResources/Command/MedicalLicenses/NewMedicalLicenses'; break;
                case 'DeskMedicalLicenses': xpath = '/HumanResources/Command/MedicalLicenses/DeskMedicalLicenses'; break;

                //PERMISS wORK
                case 'NewPermissWork': xpath = '/HumanResources/Command/PermissWork/NewPermissWork'; break;
                case 'DeskPermissWork': xpath = '/HumanResources/Command/PermissWork/DeskPermissWork'; break;

                //HOLIDAYS
                case 'DeskHolidays': xpath = '/HumanResources/Command/Holidays/DeskHolidays'; break;
                case 'NewHolidays': xpath = '/HumanResources/Command/Holidays/NewHolidays'; break;

                //PERSONAL PROFILE
                case 'UserNewPermissWork': xpath = '/PMPI/Command/UserPermissWork/UserNewPermissWork'; break;
                case 'UserRecordPermissWork': xpath = '/PMPI/Command/UserPermissWork/UserRecordPermissWork'; break;
                case 'UserRecordMedicalLicenses': xpath = '/PMPI/Command/UserMedicalLicenses/UserRecordMedicalLicenses'; break;
                case 'UserRecordHolidays': xpath = '/PMPI/Command/UserHolidays/UserRecordHolidays'; break;

                //STAFF RECRUITMENT
                case 'ApplicantPersonalData': xpath = '/StaffRecruitment/Command/Applicants/DataPersonal'; break;
                case 'ApplicantRolePreferences': xpath = '/StaffRecruitment/Command/Applicants/RolPreference'; break;
                case 'DocumentsApplicants': xpath = '/StaffRecruitment/Command/Applicants/Documents'; break;

                // LOGIGISTIC - GESTION LOGISTICA


                //** PROCESOS MEDICION ANALISIS Y MEJORA */
                // PROCESS ASSURANCE - ASEGURAMIENTO DE GESTION POR PROCESOS
                case 'NewRecordFinding': xpath = '/ProcessAssurance/Command/Findings/FormProtocolRegister'; break;
                case 'ControlFindings': xpath = '/ProcessAssurance/Command/Findings/DeskControlContract'; break;
                case 'IPMFindings': xpath = '/ProcessAssurance/Command/Findings/DeskControlIPM'; break;
                case 'AssignedFindings': xpath = '/ProcessAssurance/Command/Findings/DeskControlProcess'; break;
                case 'UserCheckFindings': xpath = '/PMPI/Command/Findings/DeskMyEvents'; break;

                case 'FindingsContractDashboard': xpath = '/ContractManagement/Command/Findings/Dashboard'; break;
                case 'FindingsGppDashboard': xpath = '/ProcessAssurance/Command/Findings/Dashboard'; break;
                case 'FindingsRecordControlGRT': xpath = '/ProcessAssurance/Command/Findings/DeskControlGRT'; break;

                case 'FindingsContractManagement': xpath = '/ContractManagement/Command/Findings/DeskControlProcess'; break;
                case 'FindingsPlanning': xpath = '/Planning/Command/Findings/DeskControlProcess'; break;
                case 'FindingsExecutionService': xpath = '/ExecutionService/Command/Findings/DeskControlProcess'; break;
                case 'FindingsProcessAssurance': xpath = '/ProcessAssurance/Command/Findings/DeskControlProcess'; break;
                case 'FindingsReliability': xpath = '/Reliability/Command/Findings/DeskControlProcess'; break;
                case 'FindingsQualityManagement': xpath = '/QualityManagement/Command/Findings/DeskControlProcess'; break;
                case 'FindingsEHSQ': xpath = '/EHSQ/Command/Findings/DeskControlProcess'; break;
                case 'FindingsHumanResources': xpath = '/HumanResources/Command/Findings/DeskControlProcess'; break;
                case 'FindingsLogistics': xpath = '/Logistics/Command/Findings/DeskControlProcess'; break;
                case 'FindingsContractPerformance': xpath = '/ContractPerformance/Command/Findings/DeskControlProcess'; break;
                case 'FindingsProcessAssurance': xpath = '/ProcessAssurance/Command/Findings/DeskControlProcess'; break;


            }

            if (xpath != '') {
                if (optionHref) {
                    window.location.href = xpath;
                } else {
                    window.open(xpath, '_blank');
                    AnimationWallProgress(false);
                }
                
            } else {
                AnimationWallProgress(false);
            }

        })

    }

}
