/**
 * verificación de conectividad
 * validación plataforma activa
 * validación con http 200
 */
async function CheckNetwork() {
    let regstart = new Date().getTime();
    let xpath = 'https://siemens.com';

    let getOptions = {
        method: 'GET',
        headers: {
            'Accept':'application/text'
        }
    };

    const getResults = await fetch(xpath, getOptions);

    console.log('getresult: ' + await getResults.json());

    // const request = new Request(xpath, {
    //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //     mode: 'no-cors', // no-cors, *cors, same-origin
    //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //     credentials: 'omit', // include, *same-origin, omit
    //     // redirect: 'follow', // manual, *follow, error
    //     referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //     body: '',
    //     // headers: new Headers()
    // });

    // fetch(request)
    //     .then(res => {
    //         let xmessage = 'Plataforma CORE';
    //         let xtitle = '';
    //        // console.info('status: ' + res.status);
    //        // return res.json();
    //        if (res.status == 200 && res.status <300) {
    //            xtitle = 'Online';
    //            NotifyerPopUp.create(xmessage, xtitle, 'S');
    //            return res.text();
    //        } else {
    //            //    throw 'Respuesta incorrecta del servidor'
    //            xtitle = 'Offline';
    //            NotifyerPopUp.create(xmessage, xtitle, 'W');
    //        }
    //    })
    //    .then(data => {
    //        // console.log('data: ' + data);
    //        // console.log('length: ' + data.length);
    //        // console.log('isJson: ' + isJson(data));
    //     //    let jsonResponse = JSON.parse(data);
    //        let regload = new Date().getTime();
    //        console.info('Time Load: ' + ((regload - regstart) / 60).toFixed(2) + ' s');
    //    })
    //    .catch(err => {
    //        console.error('ERR: ' + err.name);
    //        console.error('ERR: ' + err.message);
    //        console.error('ERR: ' + err.toString());
    //        console.error('ERR: ' + err.stack);
    //    });
}
